.hall-selector-container {
    width: 540px;
    height: 960px;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    z-index: 1101; // header & footer = 1100

    .hall-selector {
        padding: 0;
        // top: 122px; //header + game category 72+50
        left: 0px;
        width: 300px;
        position: absolute;
        background-color: rgba($color: #1a1510, $alpha: 0.9);

        .selector-list {
            height: 50px;

            .selector-list-text {
                display: flex;
                justify-content: center;
                align-items: center;
                justify-content: center;
                font-size: 17px;
            }
        }

        .Mui-selected {
            background-color: #3c2e1e;
            border: 2px solid #7a684c;
        }
    }
}

.table1-col-cell {
    width: 540px;
    height: 249px;
    max-width: 540px !important;
    max-height: 249px !important;
    .table-cell-info {
        width: 540px;
    }
}

.table2-col-cell {
    width: 265px;
    height: 153px;
    max-width: 265px !important;
    max-height: 153px !important;
    .table-cell-info {
        width: 265px;
    }
}
.table-cell {
    .table-cell-info {
        height: 28px;
    }
}

.pull-to-refresh {
    margin-top: 122px;
}