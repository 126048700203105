.PendingChipContainer {
    width: 130px;
    height: 133px;
    position: relative;

    .PendingChipContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}