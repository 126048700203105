.gaming-seat-symbol,
.gaming-seat-symbol * {
    box-sizing: border-box;
}
.gaming-seat-symbol {
    flex-shrink: 0;
    width: 70px;
    height: 55px;
    // position: relative;
}
.gaming-seat-symbol__seat {
    // position: absolute;
    inset: 0;
}
.gaming-seat-symbol__circle {
    width: 70px;
    height: 55px;
    // position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
}

.gaming-seat-symbol__seat .ChipContainer {
    width: 0px;
    height: 0px;
    z-index: 1;
}
