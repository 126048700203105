.seat-symbol,
.seat-symbol * {
    box-sizing: border-box;
}
.seat-symbol {
    width: 50px;
    height: 37px;
    position: relative;
    .ChipContainer {
        transform-origin: 0 0;
    }
}
.seat-symbol__circle {
    height: auto;
    position: absolute;
    right: 11.25%;
    left: 11.25%;
    width: 77.5%;
    bottom: 5.36%;
    top: 5.36%;
    height: 89.29%;
    overflow: visible;
}
.seat-symbol__number {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-family: 'Arial-Bold', sans-serif;
    font-size: 18px;
    font-weight: 400;
    position: absolute;
    right: 37.69%;
    left: 38.75%;
    width: 23.56%;
    bottom: 18.75%;
    top: 19.64%;
    height: 61.61%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seat-symbol__number.seated {
    top: 10px;
    left: 20px;
}
.seat-symbol__arrow {
    height: auto;
    position: absolute;
    right: 35%;
    left: 35%;
    width: 30%;
    bottom: 105.36%;
    top: -55.36%;
    height: 50%;
    overflow: visible;
}
