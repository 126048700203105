.ChipContainer {
    position: relative;
    width: 125px;
    height: 125px;

    .chipStack {
        position: absolute;
        top: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .chipContainer {
            position: relative;
            width: 94px;
            height: 66px;

            .displayText {
                position: absolute;
                top: 43%;
                left: 50%;
                transform: translate(-50%, -50%) scaleY(0.7);
            }

            .selectedEffect {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                animation: flash 1.5s infinite;
            }

            @keyframes flash {
                50% {
                    opacity: 0.5;
                }
            }
        }

        .settingSelectedIcon {
            position: absolute;
            top: 0px;
            right: 0;
        }
        .withHoldValue {
            margin-bottom: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            .container {
                position: relative;
                width: 125px;
                height: 20px;

                .displayText {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .totalValue {
            margin-top: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            .container {
                position: relative;
                width: 125px;
                height: 30px;

                .displayText {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .chipStack > * {
        display: block;
        margin: -38px 0;
    }
}
