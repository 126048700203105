.sicbo-betarea-box {
    .betarea-tabs {
        min-height: 28px;
        height: 28px;
        background-color: #000000;
        font-size: 18px;
        .MuiTabs-indicator {
            display: none;
        }
        Button.MuiButtonBase-root.MuiTab-root {
            width: 180px;
            min-height: 28px;
            height: 28px;
            text-transform: none;
        }
        .Mui-selected {
            background-color: #8b7554;
            color: #ffffff;
        }
    }
}
//4 = game type = sicbo
#emoji-button-multibet {
    // .live-emoji-popup {
    .MuiPaper-root {
        top: 800px;
    }
    // }
}
#emoji-button-4 {
    // .live-emoji-popup {
    .MuiPaper-root {
        top: 880px;
    }
    // }
}
