html,
body {
    touch-action: pan-x pan-y; //disable double click -> zoom in
    -ms-touch-action: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    margin: 0;
    padding: 0;
    border: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    height: 100%;
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
    overflow: hidden;
}
body {
    background: url(../public/resource/assets/image/common/bg.jpg) no-repeat 50%
        0 #100e09;
    background-size: cover;
}
