.bj_seats2 {display:inline-block; overflow:hidden; background-repeat:no-repeat; background-image:url(bj_seats2.png);}

.bj_seats2.ic_bj_l {width:15px; height:16px; background-position: -206px -140px; }
.bj_seats2.ic_stand {width:14px; height:14px; background-position: -162px -119px; }
.bj_seats2.seat_bet_main_highlight {width:87px; height:85px; background-position: -89px -0px; }
.bj_seats2.ic_lost {width:20px; height:20px; background-position: -162px -140px; }
.bj_seats2.ic_bust {width:20px; height:20px; background-position: -140px -131px; }
.bj_seats2.ic_2x {width:14px; height:14px; background-position: -162px -103px; }
.bj_seats2.seat_select {width:91px; height:80px; background-position: -178px -0px; margin: 1px 2px 1px 1px}
.bj_seats2.ic_hit {width:14px; height:14px; background-position: -242px -140px; }
.bj_seats2.ic_seat_Surrender {width:12px; height:14px; background-position: -427px -144px; }
.bj_seats2.seat_bet_main {width:87px; height:85px; background-position: -0px -0px; }
.bj_seats2.green_mask {width:31px; height:20px; background-position: -475px -58px; margin: 0px 0px 0px 9px}
.bj_seats2.ic_push {width:20px; height:20px; background-position: -140px -109px; }
.bj_seats2.bj_pair_overlay {width:80px; height:26px; background-position: -384px -116px; }
.bj_seats2.ic_seat_insurance {width:14px; height:14px; background-position: -162px -87px; }
.bj_seats2.ic_side2 {width:12px; height:16px; background-position: -384px -144px; }
.bj_seats2.btnNext {width:31px; height:56px; background-position: -475px -0px; margin: 0px 0px 0px 9px}
.bj_seats2.ic_win {width:20px; height:20px; background-position: -140px -87px; }
.bj_seats2.betBehind_current {width:80px; height:56px; background-position: -393px -0px; }
.bj_seats2.betBehind_normal {width:80px; height:56px; background-position: -0px -87px; }
.bj_seats2.ic_seat_pair {width:17px; height:14px; background-position: -223px -140px; }
.bj_seats2.bj_main_overlay {width:58px; height:43px; background-position: -324px -108px; }
.bj_seats2.ic_bj_s {width:13px; height:14px; background-position: -412px -144px; }
.bj_seats2.seated_normal {width:80px; height:56px; background-position: -178px -82px; }
.bj_seats2.non_seated {width:62px; height:50px; background-position: -260px -108px; }
.bj_seats2.btnInsurance {width:56px; height:56px; background-position: -82px -87px; }
.bj_seats2.ic_leave_seat {width:24px; height:24px; background-position: -475px -80px; }
.bj_seats2.seated_current {width:80px; height:56px; background-position: -393px -58px; }
.bj_seats2.yellow_pointer {width:42px; height:36px; background-position: -466px -116px; margin: 1px 4px 4px 4px}
.bj_seats2.seat_bet_pair {width:120px; height:52px; background-position: -271px -0px; }
.bj_seats2.seat_bet_pair_highlight {width:120px; height:52px; background-position: -271px -54px; }
.bj_seats2.ic_side {width:12px; height:16px; background-position: -398px -144px; }
.bj_seats2.ic_bj {width:20px; height:20px; background-position: -184px -140px; }
