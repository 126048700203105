.teenpatti-table-info {
    width: 540px;
    height: 232px;
    background-color: #000000;
    position: relative;

    .MuiAppBar-root {
        height: 28px;
        position: static;

        .teenpatti-table-info-tabs {
            min-height: 28px;
            height: 28px;
            background-color: #000000;
            font-size: 15px;

            .MuiTabs-indicator {
                display: none;
            }

            .teenpatti-table-info-tab {
                width: 33.33%;
                min-height: 28px;
                height: 28px;
                text-transform: none;
                padding-left: 0;
                padding-right: 0;
            }

            .Mui-selected {
                background-color: #8b7554;
                color: #ffffff;
            }
        }
    }
}

.teenpatti-betlimit-popup {
    width: 540px;
    height: 960px;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 0;
    margin: 0;

    .title {
        padding-top: 100px;
        font-size: 29px;
        text-align: center;
    }

    .content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
}
