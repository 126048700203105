.teenpatti-statistics {
    display: flex;
    direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 100%;
    width: 100%;
    background-color: black;

    .bar{
        width: 230px;
        height: 15px;
        background: linear-gradient(to right, rgba(60, 112, 103, 0.1), rgba(60, 112, 103, 0.425));
    }
}