.AndarBaharTwoColumnMainContainer {
    width: 265px;
    height: 97px;
    background-color: #ffffff;
    position: relative;

    .bigRoad {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 256px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.AndarBaharOneColumnMainContainer {
    width: 540px;
    height: 193px;
    background-color: #ffffff;
    position: relative;

    .beadRoad {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 192px;
        height: 193px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigRoad {
        position: absolute;
        top: 0px;
        left: 192px;
        width: 336px;
        height: 193px;
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.AndarBaharInGameMainContainer {
    width: 540px;
    height: 172px;
    background-color: #ffffff;
    position: relative;

    .beadRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 168px;
        height: 172px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigRoad {
        position: absolute;
        top: 0.5px;
        left: 172px;
        width: 364px;
        height: 172px;
        display: grid;
        grid-template-columns: repeat(13, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.AndarBaharSwitchTableMapMainContainer {
    .bigRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 180px;
        height: 94px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}