.multibet-poker-show-backdrop {
    color: #fff;
    background-color: rgba($color: #000000, $alpha: 0.8) !important;
    position: absolute !important;
    .poker-show {
        width: 540px;
        height: 127px;
        .poker-box {
            position: absolute !important;
            top: 10px;
            width: 540px;
            justify-content: center;
            align-items: center;
            div.poker.cardNaN {
                width: 62px;
                height: 81px;
                background-image: none;
            }
            div.poker.cardundefined {
                width: 62px;
                height: 81px;
                background-image: none;
            }
            div.poker:nth-child(1) {
                margin-right: 14px;
            }
            div.poker:nth-child(3) {
                margin-left: 5px;
                margin-right: 25px;
            }
            div.poker:nth-child(5) {
                margin-left: 25px;
                margin-right: 5px;
            }
            div.poker:nth-child(6) {
                margin-right: 14px;
            }
        }

        .point-box {
            position: absolute !important;
            bottom: 10px;
            width: 377px;
            .MuiTypography-root {
                width: 90px;
                height: 40px;
                font-size: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
