.andarbahar-bet-rate {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-self: center;
    width: 75%;
    background-color: #555555;
    border-radius: 5px 5px 5px 5px;
}

.andarbahar-andar-win-item {
    display: flex;
    justify-content: center;
    background-color: #004596;
    border-radius: 5px 0 0 5px;
    height: 37px;
    padding-left: 5px;
}

.andarbahar-bahar-win-item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #ad0000;
    border-radius: 0 5px 5px 0;
    padding-right: 5px;
    height: 37px;
}

.andarbahar-andar-gamecard-win-item {
    display: flex;
    justify-content: center;
    background-color: #004596;
    height: 17.5px;
}

.andarbahar-bahar-gamecard-win-item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #ad0000;
    height: 17.5px;
}

.andarbahar-bahar-sidebet-win-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 1px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.85) 100%), rgba(60, 112, 103, 0.50);
    height: 9px;
    width: 145.5px;

    .andarbahar-bahar-sidebet-win-thumb-item {
        display: flex;
        border-radius: 1px;
        background: #a28E6B;
        height: 100%;
    }
}