.roulette-table-info-container {
    .table-info-tabs {
        .table-info-tab0,
        .table-info-tab1,
        .table-info-tab2 {
            width: 180px;
            height: 54px;
            min-width: 180px;
            min-height: 54px;
            text-transform: none;
            font-size: 15px;
            svg.MuiSvgIcon-root {
                position: absolute;
                right: 5px;
            }
        }
        .MuiTabs-indicator {
            display: none;
        }
        .Mui-selected {
            background-color: #6a573d;
            color: #ffffff;
        }
    }

    .history-tab {
        height: 135px;      
    }
}