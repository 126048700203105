.sicbo-table-info-container {
    // #lobby-tabpanel-0, #lobby-tabpanel-1, #lobby-tabpanel-2 {
    //     margin-top: -1px;
    // }
    // .table-info-tab {
    //     margin-top: -1px;
    // }
    .table-info-tabs {
        .table-info-tab0,
        .table-info-tab1,
        .table-info-tab2 {
            width: 180px;
            height: 54px;
            min-width: 180px;
            min-height: 54px;
            text-transform: none;
            font-size: 15px;
            svg.MuiSvgIcon-root {
                position: absolute;
                right: 5px;
            }
        }
        .MuiTabs-indicator {
            display: none;
        }
        .Mui-selected {
            background-color: #6a573d;
            color: #ffffff;
        }
    }
    .history-tab {
        height: 190px;
        .column-top {
            height: 121px !important;
            padding: 12px 0 2px 0 !important;
            font-size: 12px !important;
            .dices_stroke.MuiBox-root {
                zoom: 0.44 !important;
            }
        }
        .column-bottom {
            height: 40px !important;
            .MuiGrid-root:nth-child(1):not(.dice_triple) {
                height: 15px !important;
                font-size: 12px;
            }
            .MuiGrid-root:nth-child(2) {
                font-size: 15px;
            }
            .dice_triple {
                height: 40px !important;
                font-size: 15px;
            }
        }
    }
}
