.pokdeng-table-info {
    width: 540px;
    background-color: #000000;
    position: relative;

    .MuiAppBar-root {
        height: 30px;
        position: static;

        .pokdeng-table-info-tabs {
            min-height: 30px;
            height: 30px;
            background-color: #000000;
            font-size: 15px;

            .MuiTabs-indicator {
                display: none;
            }

            .pokdeng-table-info-tab {
                width: 270px;
                min-height: 30px;
                height: 30px;
                text-transform: none;
            }

            .Mui-selected {
                background-color: #8b7554;
                color: #ffffff;
            }
        }
    }
}
