.refresh-button-container {
    width: 26px;
    height: 26px;
    position: relative;
    .refresh-button-bg {
        div {
            position: absolute;
            top: 50%;
            left: 50%;
        }
        .brownBG {
            margin: -33px 0 0 -31px;
        }
        .refresh-grp {
            margin: 0px 0 0 0px;
        }
        .ic_refresh {
            margin: -24px 0 0 -18px;
        }
    }
}

