.table-info {
    width: 540px;
    background-color: #000000;
    position: relative;
    .MuiAppBar-root {
        height: 28px;
        position: static;
        .table-info-tabs {
            min-height: 28px;
            height: 28px;
            background-color: #000000;
            // color: #ffffff;
            font-size: 15px;
            .MuiTabs-indicator {
                display: none;
            }
            .roadmap-type-button {
                min-width: 35px;
                width: 35px;
                min-height: 28px;
                height: 28px;
            }
            .table-info-tab1 {
                width: 235px;
                min-height: 28px;
                height: 28px;
                text-transform: none;
            }
            .table-info-tab2 {
                width: 270px;
                min-height: 28px;
                height: 28px;
                text-transform: none;
            }
            .Mui-selected {
                background-color: #8b7554;
                color: #ffffff;
            }
        }
    }
}
