.switch-table-button {
    min-width: 40px !important;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    margin-right: 10px !important;
}
.switch-table-button-dialog {
    .MuiPaper-root {
        background-color: rgba(0, 0, 0, 0);
        box-shadow: none;
        position: absolute;
        right: 0;
        margin: 0;
        height: 100%;
        width: 250px;
        max-height: 100%;
        .switch-table-close {
            position: absolute;
            right: 205px;
            top: 318px;
            max-width: 45px;
            max-height: 205px;
            width: 45px;
            height: 205px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .switch-table-box {
            position: absolute;
            overflow: hidden;
            right: 0;
            top: 0;
            height: 960px;
            .title {
                height: 45px;
                width: 205px;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .table-cell-list {
                // overflow-y: scroll;
                // width: 205px;
                //  height: 915px;
                background-color: #000000;
                .baccarat-tab,
                .other-games-tab {
                    width: 182px;
                    height: 122px;
                    margin: 5px 0 0 5px;
                    .host-name {
                        width: 87px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;
                    }
                    .countdown-box {
                        width: 95px;
                    }
                    .roadmap-box {
                        position: relative;
                        height: 94px;
                        // color: #000000;
                        // background-color: #ffffff;
                        .here-box {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 94px;
                            top: 0;
                            background-color: rgba(0, 0, 0, 0.6);
                            font-size: 18px;
                            color: #ffffff;
                        }
                    }
                    .icon-box {
                        position: relative;
                        display: flex;
                        top: -122px;
                        height: 27px;
                        align-items: center;
                        justify-content: flex-end;
                        padding-right: 2px;
                    }
                }
            }
        }
    }
}
