.sedie-table-info {
    width: 540px;
    height: 225px;
    background-color: #000000;
    position: relative;

    .MuiAppBar-root {
        height: 28px;
        position: static;

        .sedie-table-info-tabs {
            min-height: 28px;
            height: 28px;
            font-size: 15px;

            .MuiTabs-indicator {
                display: none;
            }

            .sedie-table-info-tab {
                width: 33.33%;
                min-height: 28px;
                height: 28px;
                text-transform: none;
                padding-left: 0;
                padding-right: 0;
            }

            .Mui-selected {
                background-color: #8b7554;
                color: #ffffff;
            }
        }
    }
}