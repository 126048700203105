.teenpatti-poker-show-backdrop {
    color: #fff;
    position: absolute !important;
    .teenpatti-poker-show {
        width: 540px;
        height: 100px;
    }

    .teenpatti-player-card {
        display: flex;
        justify-content: center;
        align-content: flex-start;
        gap: 6px;
        width: 47%;
        .title {
            font-size: 28px;
            justify-content: center;
            height: 30px;
            margin-bottom: 5px;
        }
        .card-group {
            justify-content: flex-start;
            width: 148px;
            gap: 8px;
        }
        .card-pattern {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: white;
            width: 100%;
            border-radius: 5px;
            height: 25px;
        }
    }

    .teenpatti-six-card {
        font-size: 14px;
        background-color: black;
        color: white;
        width: 100%;
        height: 90px;
        border-radius: 5px;
        border: 2px solid #655840;

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            gap: 7px;
            .card-group {
                display: flex-start;
                justify-content: center;
                align-items: center;
                gap: 5px;
            }
            .six-card-pattern {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                background-color: #655840;
                color: white;
                border-radius: 2px;
                height: 25px;
                width: 200px;
            }
        }
        .six-card-bonus {
            position: absolute;
            display: flex;
            left: 70px;
            justify-content: center;
            align-content: center;
            align-items: center;
            font-size: 14px;
            background-color: #655840;
            color: white;
            min-width: 40px;
            max-width: 70px;
            padding: 0 5px;
            border-radius: 0 0 5px 5px;
            height: 40px;
            text-align: center;
            line-height: 1;
        }
    }
}

