.roulette {display:inline-block; overflow:hidden; background-repeat:no-repeat; background-image:url(roulette.png);}

.roulette.h5_result_bg_3 {width:433px; height:174px; background-position: -0px -352px; }
.roulette.btn_expend {width:112px; height:112px; background-position: -870px -230px; }
.roulette.roul_switch_betting_mode2_png {width:112px; height:113px; background-position: -870px -0px; }
.roulette.h5_result_bg_5 {width:433px; height:174px; background-position: -435px -176px; }
.roulette.roul_switch_betting_mode1_png {width:112px; height:113px; background-position: -870px -115px; }
.roulette.h5_result_bg_4 {width:433px; height:174px; background-position: -0px -176px; }
.roulette.btn_collapse {width:112px; height:112px; background-position: -870px -344px; }
.roulette.h5_result_bg_2 {width:433px; height:174px; background-position: -435px -0px; }
.roulette.h5_result_bg_1 {width:433px; height:174px; background-position: -0px -0px; }
.roulette.bg_result_png {width:415px; height:68px; background-position: -435px -352px; }
