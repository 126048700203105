.sicbo-statistics-button {
    min-width: 44px !important;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    margin: 0 !important;
    background-color: rgba(0, 0, 0, 0) !important;
    position: absolute !important;
    bottom: 84px;
}

.sicbo-statistics-popup {
    position: absolute !important;
    width: 238px;
    height: 73px;
    top: 828px !important;
    left: 151px !important;
    margin: 0;
    padding: 0;
    background-color: #000000;
    .MuiButton-root {
        width: 40px;
        height: 73px;
        min-width: auto;
        min-height: auto;
    }
    .left-btn {
        .MuiSvgIcon-root {
            margin-left: -23px;
        }
    }
    .right-btn {
        position: absolute;
        right: 0;
        .MuiSvgIcon-root {
            margin-right: -23px;
        }
    }
    .statistics {
        width: 204px;
        height: 73px;
        position: absolute;
        top: 0px;
        left: 17px;
        .point {
            .hot,
            .cold {
                width: 204px;
                .MuiGrid-item {
                    width: 34px;
                    height: 100%;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .hot {
                height: 37px;
                .MuiGrid-item:nth-of-type(odd) {
                    background-color: #7d0b0b;
                }
                .MuiGrid-item:nth-of-type(even) {
                    background-color: #580000;
                }
            }
            .cold {
                height: 36px;
                .MuiGrid-item:nth-of-type(odd) {
                    background-color: #2f3068;
                }
                .MuiGrid-item:nth-of-type(even) {
                    background-color: #352343;
                }
            }
        }
        .dice {
            .d123,
            .d456 {
                width: 204px;
                .rate-text {
                    font-size: 11px;
                    margin-left: 5px;
                }
            }
            .d123 {
                height: 37px;
            }
            .d456 {
                height: 36px;
            }
        }
        .bet-type {
            .B,
            .S,
            .T,
            .E,
            .O,
            .Null {
                width: 95px;
                height: 12px;
                .title {
                    font-size: 11px;
                    z-index: 1;
                    width: 12px;
                    height: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .per {
                    font-size: 12px;
                    z-index: 1;
                    margin-right: 5px;
                }
            }
            .B,
            .E {
                .title {
                    background-color:#9e0000;
                }
                .MuiLinearProgress-bar {
                    background-color: #c80000 !important;
                }
            }
            .S,
            .O {
                .title {
                    background-color: #2e2e86;
                }
                .MuiLinearProgress-bar {
                    background-color: #2d30b1 !important;
                }
            }
            .T {
                .title {
                    background-color: #0e8010;
                }
                .MuiLinearProgress-bar {
                    background-color: #029201 !important;
                }
            }
        }
    }
}
