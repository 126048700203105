.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition {
    // height: 62.2vw !important;
    min-height: 62.2vw !important;
    overflow: hidden;
}
.MuiDateCalendar-root {
    width: 100vw !important;
    max-height: 100vw !important;
    .MuiPickersCalendarHeader-root {
        .MuiPickersCalendarHeader-label {
            font-size: 6vw !important;
        }
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit {
            width: 6vw !important;
            height: 6vw !important;
        }
    }
    .MuiDayCalendar-header {
        background-color: rgba($color: #876f4d, $alpha: 0.9);
        .MuiDayCalendar-weekDayLabel {
            width: 12vw;
            height: 12vw;
            font-size: 5vw;
            margin: 0;
            padding: 0;
        }
    }
}
