.chip-list {
    // width: 390px;
    height: 60px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    .MuiGrid-root.MuiGrid-item:not(:first-child) {
        margin-left: -6px;
    }
    .left-gradient {
        position: absolute;
        left: 74px;
        width: 41px;
        height: 60px;
    }
    .right-gradient{
        position: absolute;
        right: 73px;
        width: 41px;
        height: 60px;
    }
}

.chip-list-vertical {
    width: 96px;
    height: 255px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    .MuiGrid-root.MuiGrid-item:not(:first-child) {
        margin-left: 0px;
    }
    .top-gradient {
        position: absolute;
        left: 0px;
        width: 96px;
        height: 60px;
    }
    .bottom-gradient{
        position: absolute;
        bottom: 0px;
        width: 96px;
        height: 60px;
    }
}

