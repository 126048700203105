.summaryList {
    display: flex;
    direction: row;
    gap: 10px;
    padding-left: 10px;
    align-self: center;

    .banker {
        color: #c70000;
    }

    .player {
        color: #2d30b1;
    }

    .tie {
        color: #009303;
    }
}

.multi-bet-baccarat-tabs {
    height: 28px;
    background-color: #000000;
    font-size: 18px;
    position: relative;

    .MuiTabs-indicator {
        display: none;
    }

    .multi-bet-baccarat-tab {
        min-height: 28px;
        width: 270px;
        height: 28px;
        color: #967e5a;
        font-size: 20px;
        text-transform: none;
    }

    .Mui-selected {
        background-color: #8b7554;
        color: #ffffff !important;
    }
}
