.scratch_card {display:inline-block; overflow:hidden; background-repeat:no-repeat; background-image:url(scratch_card.png);}

.scratch_card.event_scratch_card_scratch_8 {width:368px; height:167px; background-position: -1480px -384px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_7 {width:368px; height:167px; background-position: -1110px -384px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_6 {width:368px; height:167px; background-position: -740px -384px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_5 {width:368px; height:167px; background-position: -370px -384px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_4 {width:368px; height:167px; background-position: -0px -384px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_3 {width:368px; height:167px; background-position: -1480px -215px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_2 {width:368px; height:167px; background-position: -1110px -215px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_1 {width:368px; height:167px; background-position: -740px -215px; margin: 23px 31px 23px 31px}
.scratch_card.event_invalid_scratch_card_bg {width:430px; height:213px; background-position: -1296px -0px; }
.scratch_card.event_3rd_scratch_card_bg {width:430px; height:213px; background-position: -864px -0px; }
.scratch_card.event_2nd_scratch_card_bg {width:430px; height:213px; background-position: -432px -0px; }
.scratch_card.event_1st_scratch_card_bg {width:430px; height:213px; background-position: -0px -0px; }
.scratch_card.event_scratch_card_scratch_9 {width:368px; height:167px; background-position: -370px -215px; margin: 23px 31px 23px 31px}
.scratch_card.event_scratch_card_scratch_10 {width:368px; height:167px; background-position: -0px -215px; margin: 23px 31px 23px 31px}
