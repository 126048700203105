.roulette-two-column-main-container {
    width: 265px;
    height: 97px;
    background-color: #ffffff;
    position: relative;

    .roulette-beadRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 256px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: column;
    }
}

.roulette-one-column-main-container {
    width: 540px;
    height: 193px;
    background-color: #ffffff;
    position: relative;

    .roulette-beadRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 510px;
        height: 193px;
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.roulette-in-game {
    width: 540px;
    height: 199px;
    background-color: #ffffff;
    position: relative;

    .roulette-road {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 529px;
        height: 134px;
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.roulette-road {
    position: absolute;
    top: 0.5px;
    left: 0.5px;
    width: 529px;
    height: 134px;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;
}

.roulette-history-container {
    width: 540px;
    height: 135px;
    background-color: #ffffff;
    position: relative;

    .roulette-beadRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 529px;
        height: 135px;
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.roulette-switch-table-map-main-container {
    overflow: hidden;
    
    .roulette-beadRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 182px;
        height: 92px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: column;
    }
}

.roulette-roadmap-container {
    .roadmap-tabs {
        height: 29px;
        min-height: 29px;
        .MuiButtonBase-root.MuiTab-root {
            width: 180px;
            height: 29px;
            min-width: 180px;
            min-height: 29px;
            text-transform: none;
            font-size: 14px;
            background-color: #6a573d;
            padding: 0;
            margin: 0;
            color: rgba(255, 255, 255, 0.6);
        }
        .MuiTabs-indicator {
            display: none;
        }
        .Mui-selected {
            background-color: #6a573d;
            color: #ffffff !important;
        }
    }
    .roadmap-tab {
        position: relative;
        width: 100%;
        height: 132px;

        .red-black-road,
        .big-small-road,
        .odd-even-road {
            position: absolute;
            top: 0.5px;
            left: 0.5px;
            width: 528px;
            height: 133px;
            display: grid;
            grid-template-columns: repeat(24, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-auto-flow: column;
        }
    }
}

.roulette-summary-list {
    display: flex;
    position: relative;
    direction: row;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    align-items: center;
    align-content: center;
    padding-left: 10px;
    align-self: center;
    width: 100%;
    height: 20px;
    font-size: 16px;
    background-color: black;

    .summary-red {
        color: #ff0000;
    }
    .summary-odd {
        color: #ff0000;
    }
    .summary-big {
        color: #ff0000;
    }
    .summary-black {
        color: #7c7c7c;
    }
    .summary-even {
        color: #7c7c7c;
    }
    .summary-small {
        color: #7c7c7c;
    }
    .summary-zero {
        color: #009303;
    }
}
