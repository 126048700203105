.sedie-statistics-left {
    display: flex;
    direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 200px;
    width: 100%;
    gap: 25px;

    .sedie-odd-even-grp {
        display: flex;
        direction: row;
        flex-wrap: nowrap;
        align-self: center;
        width: 70%;
    }

    .sedie-small-big-grp {
        display: flex;
        direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-self: center;
        width: 70%;
        border-radius: 5px;
        background-color: #0f9315;
    }

    .sedie-odd-small-item {
        display: flex;
        justify-content: center;
        background-color: #084694;
        border-radius: 5px 0 0 5px;
        height: 45px;
        padding-left: 5px;
    }

    .sedie-even-big-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: #d10011;
        border-radius: 0 5px 5px 0;
        padding-right: 5px;
        height: 45px;
    }
}

.sedie-statistics-right {
    margin-top: 41px;
    align-content: center;
    align-self: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
}