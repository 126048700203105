.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: #c3a475;
    border-radius: 50%;
    font-size: 10px;
    color: #000;
    text-shadow:
        -1px 0px 0px #c3a475,
        1px 0px 0px #c3a475,
        0px -1px 0px #c3a475,
        0px 1px 0px #c3a475;
}

.balanceInfo {
    font-size: 18px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}

.eventInfo {
    font-size: 18px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: justify;
}

.refreshIcon {
    scale: 0.7;
}