.blackjack-history th,
.blackjack-history td {
    text-align: center !important;
    border: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}
.blackjack-history th {
    color: #706046;
}
.blackjack-history tr:not(:first-child) td {
    padding-top: 0 !important;
}
.blackjack-history .banker-point-cell {
    vertical-align: baseline;
}
.blackjack-history::-webkit-scrollbar-thumb {
    background-color: #64573f99;
}
