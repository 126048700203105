.promotion_tool {display:inline-block; overflow:hidden; background-repeat:no-repeat; background-image:url(promotion_tool.png);}

.promotion_tool.event_total_prize_title_bg {width:35px; height:30px; background-position: -604px -158px; }
.promotion_tool.event_total_prize_bg {width:493px; height:98px; background-position: -0px -0px; margin: 11px 4px 1px 3px}
.promotion_tool.event_congratulation_bg {width:344px; height:34px; background-position: -258px -158px; }
.promotion_tool.event_caption_arrow {width:52px; height:3px; background-position: -258px -150px; }
.promotion_tool.ic_ranking {width:20px; height:20px; background-position: -641px -158px; margin: 5px 5px 5px 5px}
.promotion_tool.ic_gacha {width:30px; height:26px; background-position: -870px -205px; margin: 2px 0px 2px 0px}
.promotion_tool.event_icn_clock {width:12px; height:12px; background-position: -955px -223px; margin: 2px 2px 2px 2px}
.promotion_tool.event_1st_selected {width:127px; height:150px; background-position: -0px -100px; margin: 13px 1px 1px 1px}
.promotion_tool.event_1st_unselected {width:127px; height:150px; background-position: -129px -100px; margin: 13px 1px 1px 1px}
.promotion_tool.event_2nd_selected {width:127px; height:151px; background-position: -753px -0px; margin: 13px 1px 0px 1px}
.promotion_tool.event_2nd_unselected {width:127px; height:151px; background-position: -882px -0px; margin: 13px 1px 0px 1px}
.promotion_tool.event_3rd_selected {width:127px; height:156px; background-position: -495px -0px; margin: 8px 1px 0px 1px}
.promotion_tool.event_3rd_unselected {width:127px; height:156px; background-position: -624px -0px; margin: 8px 1px 0px 1px}
.promotion_tool.event_arrow_down {width:12px; height:14px; background-position: -480px -116px; margin: 3px 4px 3px 4px}
.promotion_tool.event_arrow_up {width:12px; height:14px; background-position: -480px -100px; margin: 3px 4px 3px 4px}
.promotion_tool.event_btn_bg {width:220px; height:48px; background-position: -258px -100px; }
.promotion_tool.event_btn_close {width:28px; height:28px; background-position: -987px -191px; }
.promotion_tool.event_gem {width:19px; height:16px; background-position: -987px -221px; }
.promotion_tool.event_ic_details {width:14px; height:15px; background-position: -1008px -221px; margin: 1px 1px 1px 1px}
.promotion_tool.event_level_arrow {width:12px; height:5px; background-position: -480px -146px; margin: 1px 0px 0px 0px}
.promotion_tool.event_no_1 {width:42px; height:40px; background-position: -666px -158px; margin: 3px 3px 5px 3px}
.promotion_tool.event_no_2 {width:38px; height:40px; background-position: -710px -158px; margin: 3px 5px 5px 5px}
.promotion_tool.event_no_3 {width:28px; height:30px; background-position: -957px -191px; margin: 13px 10px 5px 10px}
.promotion_tool.event_scratch_card_back {width:202px; height:50px; background-position: -462px -194px; margin: 0px 1px 0px 1px}
.promotion_tool.event_scratch_card_back_invalid {width:202px; height:50px; background-position: -258px -194px; margin: 0px 1px 0px 1px}
.promotion_tool.event_scratch_card_start {width:202px; height:50px; background-position: -753px -153px; margin: 0px 1px 0px 1px}
.promotion_tool.event_scratch_card_start_invalid {width:202px; height:50px; background-position: -666px -205px; margin: 0px 1px 0px 1px}
.promotion_tool.event_total_price_bg {width:56px; height:36px; background-position: -957px -153px; }
.promotion_tool.event_checkbox {width:12px; height:12px; background-position: -641px -180px; }
.promotion_tool.event_checkbox_selected {width:12px; height:12px; background-position: -480px -132px; }
.promotion_tool.event_hints {width:24px; height:20px; background-position: -929px -205px; }
.promotion_tool.event_hints_arrow {width:10px; height:6px; background-position: -1011px -0px; }
.promotion_tool.event_hints_left {width:25px; height:20px; background-position: -902px -205px; margin: 0px 1px 0px 1px}
