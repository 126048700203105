.container {
    width: 540px;
    height: 960px;
    overflow: hidden;
}

svg {
    text-rendering: optimizeSpeed;
}

svg.roadmap-cell {
    width: 100%;
    height: 100%;
}

.lite-ui svg {
    shape-rendering: optimizeSpeed;
}
