.two-column-main-container {
    width: 265px;
    height: 97px;
    background-color: #ffffff;
    position: relative;
}

.one-column-main-container {
    width: 540px;
    height: 193px;
    background-color: #ffffff;
    position: relative;
}
