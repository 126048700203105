.bacc-poker-show-backdrop {
    color: #fff;
    background-color: rgba($color: #000000, $alpha: 0.8) !important;
    position: absolute !important;
    .poker-show {
        width: 540px;
        height: 225px;
        .poker-box {
            position: absolute !important;
            top: 25px;
            width: 540px;
            justify-content: center;
            align-items: center;
            div.poker.cardNaN {
                width: 62px;
                height: 81px;
                background-image: none;
            }
            div.poker.cardundefined {
                width: 62px;
                height: 81px;
                background-image: none;
            }
            div.poker:nth-child(1) {
                margin-right: 14px;
            }
            div.poker:nth-child(3) {
                margin-left: 5px;
                margin-right: 25px;
            }
            div.poker:nth-child(4) {
                margin-left: 0;
                margin-right: 5px;
            }
            div.poker:nth-child(5) {
                margin-right: 14px;
            }
        }

        .point-box {
            position: absolute !important;
            bottom: 25px;
            width: 305px;
            .MuiTypography-root {
                width: 140px;
                height: 72px;
                font-size: 39px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
