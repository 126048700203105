.promotion_tool_figures {display:inline-block; overflow:hidden; background-repeat:no-repeat; background-image:url(promotion_tool_figures.png);}

.promotion_tool_figures.event_USD {width:33px; height:53px; background-position: -192px -0px; margin: 3px 1px 0px 1px}
.promotion_tool_figures.event_THB {width:30px; height:53px; background-position: -221px -55px; margin: 3px 2px 0px 3px}
.promotion_tool_figures.event_MYR {width:58px; height:41px; background-position: -0px -0px; margin: 9px 1px 6px 1px}
.promotion_tool_figures.event_KRW {width:47px; height:47px; background-position: -60px -0px; margin: 6px 1px 3px 1px}
.promotion_tool_figures.event_JPY {width:37px; height:47px; background-position: -0px -43px; margin: 6px 0px 3px 0px}
.promotion_tool_figures.event_INR {width:30px; height:47px; background-position: -34px -202px; margin: 6px 0px 3px 1px}
.promotion_tool_figures.event_IDR {width:44px; height:47px; background-position: -109px -0px; margin: 9px 0px 0px 1px}
.promotion_tool_figures.event_GBP {width:34px; height:48px; background-position: -113px -49px; margin: 5px 1px 3px 0px}
.promotion_tool_figures.event_EUR {width:35px; height:47px; background-position: -39px -49px; margin: 6px 0px 3px 1px}
.promotion_tool_figures.event_dot {width:11px; height:11px; background-position: -238px -127px; margin: 42px 1px 3px 1px}
.promotion_tool_figures.event_comma {width:12px; height:15px; background-position: -238px -110px; margin: 41px 1px 0px 2px}
.promotion_tool_figures.event_clock_colon {width:6px; height:16px; background-position: -238px -140px; }
.promotion_tool_figures.event_clock_9 {width:29px; height:52px; background-position: -207px -110px; }
.promotion_tool_figures.event_clock_8 {width:29px; height:52px; background-position: -176px -105px; }
.promotion_tool_figures.event_clock_7 {width:29px; height:52px; background-position: -217px -164px; }
.promotion_tool_figures.event_clock_6 {width:29px; height:52px; background-position: -186px -164px; }
.promotion_tool_figures.event_clock_5 {width:29px; height:52px; background-position: -155px -159px; }
.promotion_tool_figures.event_clock_4 {width:29px; height:52px; background-position: -124px -153px; }
.promotion_tool_figures.event_clock_3 {width:29px; height:52px; background-position: -93px -149px; }
.promotion_tool_figures.event_clock_2 {width:29px; height:52px; background-position: -62px -148px; }
.promotion_tool_figures.event_clock_1 {width:29px; height:52px; background-position: -31px -148px; }
.promotion_tool_figures.event_clock_0 {width:29px; height:52px; background-position: -0px -141px; }
.promotion_tool_figures.event_9 {width:34px; height:48px; background-position: -149px -53px; margin: 5px 3px 3px 3px}
.promotion_tool_figures.event_8 {width:34px; height:48px; background-position: -185px -55px; margin: 5px 3px 3px 3px}
.promotion_tool_figures.event_7 {width:34px; height:47px; background-position: -0px -92px; margin: 6px 3px 3px 3px}
.promotion_tool_figures.event_6 {width:33px; height:48px; background-position: -36px -98px; margin: 5px 4px 3px 3px}
.promotion_tool_figures.event_5 {width:32px; height:47px; background-position: -0px -202px; margin: 6px 3px 3px 5px}
.promotion_tool_figures.event_4 {width:35px; height:47px; background-position: -76px -49px; margin: 6px 2px 3px 3px}
.promotion_tool_figures.event_3 {width:33px; height:48px; background-position: -106px -99px; margin: 5px 4px 3px 3px}
.promotion_tool_figures.event_2 {width:33px; height:48px; background-position: -71px -98px; margin: 5px 4px 3px 3px}
.promotion_tool_figures.event_1 {width:25px; height:47px; background-position: -227px -0px; margin: 6px 10px 3px 5px}
.promotion_tool_figures.event_0 {width:33px; height:48px; background-position: -141px -103px; margin: 5px 4px 3px 3px}
.promotion_tool_figures.event_VND {width:35px; height:51px; background-position: -155px -0px; margin: 4px 0px 1px 0px}
