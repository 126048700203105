.event-container {
    width: 540px;
    height: 960px;

    * {
        text-transform: none !important;
    }

    .btn_close {
        position: absolute;
        width: 28px;
        top: 60px;
        left: 474px;
        padding: 0;
        margin: 0;
    }

    .panel {
        position: absolute;
        width: 500px;
        height: 800px;
        top: 100px;
        left: 20px;

        .total_prize_container {
            position: relative;
            top: 470px;

            .event_total_prize_bg {
                position: absolute;
                top: 15px;
                margin: 0;
            }

            .total_prize_title_container {
                position: relative;
                display: flex;
                justify-content: center;
                height: 32px;
                margin: 0;
                text-align: center;

                .total_prize_title_text {
                    position: relative;
                    top: -40px;
                    white-space: nowrap;
                    color: #FFE09D;
                    font-size: 25px;
                    font-weight: bold;
                }
            }
        }

        .total_prize {
            position: relative;
            padding-top: 5px;
            height: 62px;
        }

        .event_date {
            position: relative;
            top: 25px;

            >* {
                font-size: 16px;
                color: #BF9A5D;
            }
        }

        .event_period {
            position: relative;
            display: flex;
            justify-content: center;
            top: 25px;
            color: #C6C6C6;
            height: 24px;
        }

        .event_remain_time {
            position: relative;
            top: 35px;
            height: 52px;
        }
    }

    .rotate180 {
        transform: rotateY(180deg);
    }

    .time_text {
        font-size: 12px;
        text-align: center;
        width: 80px;
        white-space: nowrap;
    }

    .button_gold,
    .button_black {
        width: 220px;
        height: 48px;
        line-height: 48px;
        border-radius: 4px;
        text-align: center;
        white-space: nowrap;
        font-size: 20px;
    }

    .button_gold {
        background-color: #BF9A5D !important;
        color: #FFFFFF;
    }

    .button_black {
        background-color: #171717 !important;
        border: 1px solid #BF9A5D;
        color: #BF9A5D;
    }

    .gacha_prize_text {
        position: relative;
        top: 15px;
    }

    .prize_selected_text,
    .gold_unselected_text,
    .silver_unselected_text,
    .bronze_unselected_text {
        font-size: 20px;
        font-weight: 700;
    }

    .prize_selected_text {
        color: #231F20;
    }

    .gold_unselected_text {
        color: #E4CE92;
    }

    .silver_unselected_text {
        color: #9FA1A0;
    }

    .bronze_unselected_text {
        color: #B08773;
    }

    .selected_gem_point,
    .unselected_gem_point {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        min-height: 24px;
    }

    .selected_gem_point {
        color: #392B0B;
    }

    .unselected_gem_point {
        color: rgba(#B8B8B8, 0.8);
    }

    .btn_scratch,
    .btn_disabled,
    .btn_scratched {
        font-size: 24px;
        font-weight: 500;
    }

    .btn_scratch {
        color: #F6F6F6;
    }

    .btn_disabled {
        color: #F6F6F6;
    }

    .btn_scratched {
        color: #BF9A5D;
    }

    @keyframes balanceEffect {
        0% {
            opacity: 0;
        }

        20% {
            opacity: 0.5;
        }

        40% {
            opacity: 0;
        }

        60% {
            opacity: 0.5;
        }

        80% {
            opacity: 0;
        }

        100% {
            opacity: 0.5;
        }
    }

    .balance_effect {
        animation: balanceEffect 3s infinite;
        border-radius: 100px;
    }

    .btn_black,
    .btn_gold {
        position: relative;
        width: auto;
        min-width: 140px;
        height: 48px;
        font-size: 20px;
        border: none;
    }

    .btn_black { 
        background: #171717; 
        border-top: #BF9A5D solid 2px; 
        border-bottom: #BF9A5D solid 2px; 
        // background-repeat: repeat-x; 
        // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23171717; %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3C/defs%3E%3Crect class='cls-2' y='46' width='10' height='2'/%3E%3Crect class='cls-2' width='10' height='2'/%3E%3Crect class='cls-1' y='2' width='10' height='44'/%3E%3C/svg%3E"); 
    } 

    .btn_gold { 
        background: linear-gradient(#d4af6f, #7e6336); 
        border-top: #BF9A5D solid 2px; 
        border-bottom: #BF9A5D solid 2px; 
        // background-repeat: repeat-x; 
        // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 10 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: url(%23linear-gradient); %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3ClinearGradient id='linear-gradient' x1='5' y1='1447.4' x2='5' y2='1392.29' gradientTransform='translate(0 1446) scale(1 -1)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23deb776'/%3E%3Cstop offset='.83' stop-color='%23806538'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect class='cls-2' y='46' width='10' height='2'/%3E%3Crect class='cls-2' width='120' height='2'/%3E%3Crect class='cls-1' y='2' width='120' height='44'/%3E%3C/svg%3E"); 
    } 

    .btn_black::before,
    .btn_gold::before,
    .btn_black::after,
    .btn_gold::after {
        position: absolute;
        content: '';
        width: 18px;
        height: 48px;
        top: -2px;
    }

    .btn_black::before,
    .btn_gold::before {
        left: -17px;
    }

    .btn_black::after,
    .btn_gold::after {
        right: -17px;
    }

    .btn_black::before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23171717; %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3C/defs%3E%3Cpolygon class='cls-2' points='15.15 46 2.32 24 15.15 2 18 2 18 0 14 0 0 24 14 48 18 48 18 46 15.15 46'/%3E%3Cpolygon class='cls-1' points='15.15 2 2.32 24 15.15 46 18 46 18 2 15.15 2'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .btn_gold::before {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: url(%23linear-gradient); %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3ClinearGradient id='linear-gradient' x1='10.16' y1='1447.4' x2='10.16' y2='1392.29' gradientTransform='translate(0 1446) scale(1 -1)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23deb776'/%3E%3Cstop offset='.83' stop-color='%23806538'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-2' points='15.15 46 2.32 24 15.15 2 18 2 18 0 14 0 0 24 14 48 18 48 18 46 15.15 46'/%3E%3Cpolygon class='cls-1' points='15.15 2 2.32 24 15.15 46 18 46 18 2 15.15 2'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .btn_black::after {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23171717; %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3C/defs%3E%3Cpolygon class='cls-2' points='2.85 2 15.68 24 2.85 46 0 46 0 48 4 48 18 24 4 0 0 0 0 2 2.85 2'/%3E%3Cpolygon class='cls-1' points='2.85 46 15.68 24 2.85 2 0 2 0 46 2.85 46'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .btn_gold::after {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: url(%23linear-gradient); %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3ClinearGradient id='linear-gradient' x1='7.84' y1='1447.4' x2='7.84' y2='1392.29' gradientTransform='translate(0 1446) scale(1 -1)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23deb776'/%3E%3Cstop offset='.83' stop-color='%23806538'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-2' points='0 0 0 2 2.85 2 15.68 24 2.85 46 0 46 0 48 4 48 18 24 4 0 0 0'/%3E%3Cpolygon class='cls-1' points='2.85 46 15.68 24 2.85 2 0 2 0 46 2.85 46'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .btn_invalid {
        filter: brightness(60%);
    }
}