.LobbySettingContainer {
    width: 72px;
    height: 72px;
    // position: relative;

    button {
        width: 72px;
        height: 72px;
    }
}
.SettingDialog.menu\.game_rulesDialog {
    .MuiAppBar-root {
        display: none;
    }
}

.SettingContainer {
    width: 69px;
    height: 52px;
    // position: relative;

    button {
        width: 52px;
        height: 52px;
    }
}

.SettingDrawer {
    .MuiPaper-root {
        width: 380px;
    }
    .SettingList {
        font-size: 21px;

        li:not(:first-child) {
            height: 60px;
        }

        .UserInfoList {
            width: 380px;
            margin: 20px 0 20px 24px;
            .UserInfoName {
                font-size: 30px;
            }
        }
    }
}
@media (max-width: 380px) {
    .SettingDrawer {
        .MuiPaper-root {
            width: 100vw;
        }

        .SettingList {
            font-size: 5.53vw;// 21 / 380
            .UserInfoList {
                width: 100vw;
                margin: 5.26vw 0 5.26vw 6.31vw;  // /380
                .UserInfoName {
                    font-size: 7.89vw; // /380
                }
            }
        }
    } 
}
