.header-container {
    width: 540px;
    height: 72px;
    background-size: 100% 100%;

    .logo {
        width: 186px;
        height: 70px;
        margin: 1px 0 1px 16px;
    }

    .rightGrp {
        display: flex;
        direction: column;
    }
}

.user-info-container {
    height: 72px;
    li {
        height: 100%;
    }
}

