.blackjack-banker-poker {
    height: 54px;
}

.blackjack-banker-poker :first-child {
    position: absolute;
    left: 10px;
}

@for $i from 2 through 20 {
    .blackjack-banker-poker :nth-child(#{$i}) {
        position: absolute;
        left: calc(#{$i} * 40px - 30px);
    }
}
