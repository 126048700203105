.dragon-tiger {
    .bigRoad {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 528px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(33, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigEyeRoad {
        position: absolute;
        top: 97px;
        left: 0px;
        width: 533px;
        height: 48px;
        display: grid;
        grid-template-columns: repeat(66, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .smallRoad {
        position: absolute;
        top: 145px;
        left: 0px;
        width: 265px;
        height: 48px;
        display: grid;
        grid-template-columns: repeat(33, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
    .cockroachRoad {
        position: absolute;
        top: 145px;
        left: 272px;
        width: 265px;
        height: 48px;
        display: grid;
        grid-template-columns: repeat(33, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.dragon-tiger-in-game-main-container {
    width: 540px;
    height: 197px;
    background-color: black;
    position: relative;

    .beadRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 532px;
        height: 168px;
        display: grid;
        grid-template-columns: repeat(19, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 533px;
        height: 84px;
        display: grid;
        grid-template-columns: repeat(38, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigEyeRoad {
        position: absolute;
        top: 84.5px;
        left: 0.5px;
        width: 267px;
        height: 42px;
        display: grid;
        grid-template-columns: repeat(38, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .smallRoad {
        position: absolute;
        top: 126.5px;
        left: 0px;
        width: 258px;
        height: 42px;
        display: grid;
        grid-template-columns: repeat(38, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .cockroachRoad {
        position: absolute;
        top: 126.5px;
        left: 265.5px;
        width: 279px;
        height: 42px;
        display: grid;
        grid-template-columns: repeat(38, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .ask-road {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 28px;
        background-color: #4b4231;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        color: #ffffff;
        .askBtn {
            position: absolute;
            bottom: 0;
            right: 10px;
            height: 28px;
            display: flex;
            direction: row;
            align-items: center;
            gap: 10px;
            padding-right: 10px;
        }
    }
    .summary-list {
        position: absolute;
        bottom: 0;
        height: 28px;
        font-size: 16px;
        display: flex;
        align-items: center;
        .dr {
            margin: 0 10px 0 10px;
            color: #3962e7;
        }
        .tr {
            margin: 0 10px 0 10px;
            color: #ff0000;
        }
        .t {
            margin: 0 10px 0 10px;
            color: #009303;
        }
    }
}
