.baccarat-tab,
.featured-tab {
    // .other {
    //     background-color: rgba($color: #000000, $alpha: 0.3);
    // }
    // .other2 {
    //     background-color: rgba($color: #000000, $alpha: 0.3);
    // }
    // .eHall {
    //     background-color: rgba($color: #2d504f, $alpha: 0.5);
    // }
    // .eHall2 {
    //     background-color: rgba($color: #2d504f, $alpha: 0.8);
    // }
    // .cHall {
    //     background-color: rgba($color: #374671, $alpha: 0.5);
    // }
    // .cHall2 {
    //     background-color: rgba($color: #374671, $alpha: 0.8);
    // }
    // .aHall {
    //     background-color: rgba($color: #704c40, $alpha: 0.5);
    // }
    // .aHall2 {
    //     background-color: rgba($color: #835e4d, $alpha: 0.8);
    // }
    .other,
    .cHall,
    .aHall,
    .mHall,
    .eHall,
    .dHall,
    .tHall {
        background-color: rgba($color: #2d504f, $alpha: 0.5);
    }
    .other2,
    .cHall2,
    .aHall2,
    .mHall2,
    .eHall2,
    .dHall2 .tHall2 {
        background-color: rgba($color: #4d8381, $alpha: 0.8);
    }
}

.other-games-tab {
    // .other {
    //     background-color: rgba($color: #000000, $alpha: 0.3);
    // }
    // .other2 {
    //     background-color: rgba($color: #000000, $alpha: 0.55);
    // }
    // .eHall {
    //     background-color: rgba($color: #2d504f, $alpha: 0.5);
    // }
    // .eHall2 {
    //     background-color: rgba($color: #4d8381, $alpha: 0.8);
    // }
    // .cHall {
    //     background-color: rgba($color: #374671, $alpha: 0.5);
    // }
    // .cHall2 {
    //     background-color: rgba($color: #4d619c, $alpha: 0.8);
    // }
    // .aHall {
    //     background-color: rgba($color: #704c40, $alpha: 0.5);
    // }
    // .aHall2 {
    //     background-color: rgba($color: #835e4d, $alpha: 0.8);
    // }
    // .mHall {
    //     background-color: rgba($color: #2d504f, $alpha: 0.5);
    // }
    // .mHall2 {
    //     background-color: rgba($color: #4d8381, $alpha: 0.8);
    // }
    .other,
    .cHall,
    .aHall,
    .mHall,
    .eHall,
    .dHall,
    .tHall {
        background-color: rgba($color: #2d504f, $alpha: 0.5);
    }
    .other2,
    .cHall2,
    .aHall2,
    .mHall2,
    .eHall2,
    .dHall2,
    .tHall2 {
        background-color: rgba($color: #4d8381, $alpha: 0.8);
    }
}
.multi-bet-games-tab {
    // .other {
    //     background-color: rgba($color: #000000, $alpha: 0.6);
    // }
    // .eHall {
    //     background-color: rgba($color: #2d504f, $alpha: 0.6);
    // }
    // .cHall {
    //     background-color: rgba($color: #374671, $alpha: 0.6);
    // }
    // .aHall {
    //     background-color: rgba($color: #704c40, $alpha: 0.6);
    // }
    // .mHall {
    //     background-color: rgba($color: #2d504f, $alpha: 0.6);
    // }
    .other,
    .cHall,
    .aHall,
    .mHall,
    .eHall,
    .dHall,
    .tHall {
        background-color: rgba($color: #2d504f, $alpha: 0.6);
    }
}
