.live-emoji-popup {
    .MuiPaper-root {
        width: 278.5px;
        position: absolute;
        top: 710px;
        left: 1px;
        margin: 0;
        padding: 0;
        font-size: 11.5px;
        background-color: rgba(0,0,0,0.8);
        border: 1.5px solid rgba(255,255,255,0.8);
        .MuiIconButton-root {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            margin: 0;
        }
    } 
}

.live-roulette-emoji-popup {
    .MuiPaper-root {
        width: 278.5px;
        position: absolute;
        top: 260px;
        left: 1px;
        margin: 0;
        padding: 0;
        font-size: 11.5px;
        background-color: rgba(0,0,0,0.8);
        border: 1.5px solid rgba(255,255,255,0.8);
        .MuiIconButton-root {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            margin: 0;
        }
    } 
}