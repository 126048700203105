.bj_seats {display:inline-block; overflow:hidden; background-repeat:no-repeat; background-image:url(bj_seats.png);}

.bj_seats.seat7_betbehind_en {width:185px; height:122px; background-position: -1309px -620px; }
.bj_seats.seat7_betbehind_kr {width:185px; height:122px; background-position: -1683px -496px; }
.bj_seats.seat6_canbet {width:189px; height:100px; background-position: -1500px -722px; margin: 22px 1px 0px 9px}
.bj_seats.seat3_normal_es {width:185px; height:122px; background-position: -1496px -496px; }
.bj_seats.seat6_normal_zh {width:185px; height:122px; background-position: -1309px -496px; }
.bj_seats.seat8_normal_cn {width:185px; height:122px; background-position: -1122px -868px; }
.bj_seats.seat2_betbehind_es {width:185px; height:122px; background-position: -1122px -744px; }
.bj_seats.seat3_normal_cn {width:185px; height:122px; background-position: -1122px -620px; }
.bj_seats.seat1_normal_cn {width:185px; height:122px; background-position: -1122px -496px; }
.bj_seats.seat3_normal_kr {width:185px; height:122px; background-position: -1683px -372px; }
.bj_seats.seat1_betbehind_zh {width:185px; height:122px; background-position: -1496px -372px; }
.bj_seats.seat2_betbehind_kr {width:185px; height:122px; background-position: -1309px -372px; }
.bj_seats.seat2_normal_kr {width:185px; height:122px; background-position: -1122px -372px; }
.bj_seats.seat_turns {width:196px; height:147px; background-position: -0px -0px; margin: 3px 4px 0px 3px}
.bj_seats.seat7_betbehind_es {width:185px; height:122px; background-position: -935px -744px; }
.bj_seats.seat5_betbehind_kr {width:185px; height:122px; background-position: -935px -620px; }
.bj_seats.seat8_normal_es {width:185px; height:122px; background-position: -935px -496px; }
.bj_seats.seat6_normal_cn {width:185px; height:122px; background-position: -935px -372px; }
.bj_seats.seat2_normal_cn {width:185px; height:122px; background-position: -1719px -124px; }
.bj_seats.seat7_normal_zh {width:185px; height:122px; background-position: -1683px -248px; }
.bj_seats.seat_insurance_canbet {width:60px; height:44px; background-position: -1309px -948px; }
.bj_seats.seat5_normal_cn {width:185px; height:122px; background-position: -1532px -124px; }
.bj_seats.seat1_canbet {width:189px; height:100px; background-position: -1687px -620px; margin: 22px 1px 0px 9px}
.bj_seats.seat1_betbehind_cn {width:185px; height:122px; background-position: -1496px -248px; }
.bj_seats.seat6_betbehind_en {width:185px; height:122px; background-position: -1345px -124px; }
.bj_seats.seat5_betbehind_cn {width:185px; height:122px; background-position: -1309px -248px; }
.bj_seats.seat6_betbehind_zh {width:185px; height:122px; background-position: -1158px -124px; }
.bj_seats.seat7_normal_es {width:185px; height:122px; background-position: -1122px -248px; }
.bj_seats.seat2_normal_zh {width:185px; height:122px; background-position: -971px -124px; }
.bj_seats.seat8_betbehind_kr {width:185px; height:122px; background-position: -935px -248px; }
.bj_seats.seat8_betbehind_en {width:185px; height:122px; background-position: -748px -868px; }
.bj_seats.seat2_betbehind_cn {width:185px; height:122px; background-position: -748px -744px; }
.bj_seats.seat5_betbehind_es {width:185px; height:122px; background-position: -748px -620px; }
.bj_seats.seat2_normal_es {width:185px; height:122px; background-position: -748px -496px; }
.bj_seats.seat2_betbehind_zh {width:185px; height:122px; background-position: -748px -372px; }
.bj_seats.seat1_betbehind_es {width:185px; height:122px; background-position: -784px -124px; }
.bj_seats.seat_insurance_bg {width:59px; height:43px; background-position: -1371px -948px; }
.bj_seats.seat3_betbehind_cn {width:185px; height:122px; background-position: -748px -248px; }
.bj_seats.seat1_normal_en {width:185px; height:122px; background-position: -561px -868px; }
.bj_seats.seat2_canbet {width:189px; height:100px; background-position: -1309px -846px; margin: 22px 1px 0px 9px}
.bj_seats.seat3_normal_en {width:185px; height:122px; background-position: -561px -744px; }
.bj_seats.seat8_normal_en {width:185px; height:122px; background-position: -561px -620px; }
.bj_seats.seat3_canbet {width:189px; height:100px; background-position: -1500px -824px; margin: 22px 1px 0px 9px}
.bj_seats.seat6_betbehind_es {width:185px; height:122px; background-position: -561px -496px; }
.bj_seats.seat7_normal_en {width:185px; height:122px; background-position: -561px -372px; }
.bj_seats.seat_pair_canbet {width:221px; height:110px; background-position: -0px -149px; margin: 1px 2px 61px 2px}
.bj_seats.seat5_betbehind_en {width:185px; height:122px; background-position: -1507px -0px; }
.bj_seats.seat3_normal_zh {width:185px; height:122px; background-position: -1320px -0px; }
.bj_seats.seat1_normal_es {width:185px; height:122px; background-position: -1133px -0px; }
.bj_seats.seat_betbehind_canbet {width:170px; height:87px; background-position: -1500px -926px; margin: 83px 27px 2px 28px}
.bj_seats.seat3_betbehind_es {width:185px; height:122px; background-position: -946px -0px; }
.bj_seats.seat7_normal_cn {width:185px; height:122px; background-position: -759px -0px; }
.bj_seats.seat6_betbehind_cn {width:185px; height:122px; background-position: -597px -124px; }
.bj_seats.seat8_betbehind_cn {width:185px; height:122px; background-position: -561px -248px; }
.bj_seats.seat8_canbet {width:189px; height:100px; background-position: -1496px -620px; margin: 22px 1px 0px 9px}
.bj_seats.seat3_betbehind_en {width:185px; height:122px; background-position: -374px -868px; }
.bj_seats.seat_arrow1 {width:27px; height:42px; background-position: -1881px -0px; }
.bj_seats.seat5_normal_kr {width:185px; height:122px; background-position: -374px -744px; }
.bj_seats.seat7_betbehind_zh {width:185px; height:122px; background-position: -374px -620px; }
.bj_seats.seat8_betbehind_es {width:185px; height:122px; background-position: -374px -496px; }
.bj_seats.seat5_normal_es {width:185px; height:122px; background-position: -374px -372px; }
.bj_seats.seat3_betbehind_zh {width:185px; height:122px; background-position: -410px -124px; }
.bj_seats.seat5_betbehind_zh {width:185px; height:122px; background-position: -374px -248px; }
.bj_seats.seat6_normal_es {width:185px; height:122px; background-position: -187px -881px; }
.bj_seats.seat5_normal_en {width:185px; height:122px; background-position: -187px -757px; }
.bj_seats.seat7_canbet {width:189px; height:100px; background-position: -1691px -722px; margin: 22px 1px 0px 9px}
.bj_seats.seat7_betbehind_cn {width:185px; height:122px; background-position: -187px -633px; }
.bj_seats.seat2_betbehind_en {width:185px; height:122px; background-position: -187px -509px; }
.bj_seats.seat1_normal_kr {width:185px; height:122px; background-position: -187px -385px; }
.bj_seats.seat_arrow3 {width:27px; height:42px; background-position: -1873px -824px; }
.bj_seats.seat3_betbehind_kr {width:185px; height:122px; background-position: -572px -0px; }
.bj_seats.seat1_betbehind_kr {width:185px; height:122px; background-position: -385px -0px; }
.bj_seats.seat8_betbehind_zh {width:185px; height:122px; background-position: -223px -124px; }
.bj_seats.seat1_normal_zh {width:185px; height:122px; background-position: -187px -261px; }
.bj_seats.seat8_normal_kr {width:185px; height:122px; background-position: -0px -881px; }
.bj_seats.seat8_normal_zh {width:185px; height:122px; background-position: -0px -757px; }
.bj_seats.seat6_betbehind_kr {width:185px; height:122px; background-position: -0px -633px; }
.bj_seats.seat_arrow2 {width:27px; height:42px; background-position: -1881px -44px; }
.bj_seats.seat7_normal_kr {width:185px; height:122px; background-position: -0px -509px; }
.bj_seats.seat5_normal_zh {width:185px; height:122px; background-position: -0px -385px; }
.bj_seats.seat5_canbet {width:189px; height:100px; background-position: -1309px -744px; margin: 22px 1px 0px 9px}
.bj_seats.seat6_normal_en {width:185px; height:122px; background-position: -0px -261px; }
.bj_seats.seat1_betbehind_en {width:185px; height:122px; background-position: -198px -0px; }
.bj_seats.seat6_normal_kr {width:185px; height:122px; background-position: -935px -868px; }
.bj_seats.seat2_normal_en {width:185px; height:122px; background-position: -1694px -0px; }
.bj_seats.seat_insurance_bg_green {width:55px; height:39px; background-position: -1432px -948px; }
.bj_seats.sithere_btn_mo {width:199px; height:64px; background-position: -1672px -926px; }
