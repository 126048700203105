.game-category-container {
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    width: 540px;
    height: 50px;
    background-color: #56452e;

    button {
        width: 150px;
        height: 50px;

        .baccarat-button,
        .other-game-button,
        .multi-bet-button {
            width: 150px;
            height: 50px;
        }
    }
    .MuiTabs-indicator {
        display: none;
    }
}
