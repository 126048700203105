.sedie-twoColumnMainContainer {
    width: 265px;
    height: 97px;
    background-color: #ffffff;
    position: relative;

    .sedie-beadRoad {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 256px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.sedie-textFont {
    color: #009303;
}

.sedie-oneColumnMainContainer {
    width: 540px;
    height: 193px;
    background-color: #ffffff;
    position: relative;

    .sedie-beadRoad {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 192px;
        height: 193px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .sedie-evenOddRoad {
        position: absolute;
        top: 0px;
        left: 192px;
        width: 336px;
        height: 193px;
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.sedie-SedieInGameMainContainer {
    width: 540px;
    height: 199px;
    background-color: #ffffff;
    position: relative;

    .sedie-road {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 532px;
        height: 168px;
        display: grid;
        grid-template-columns: repeat(19, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.sedie-SwitchTableMapMainContainer {
    .sedie-evenOddRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 180px;
        height: 94px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.sedie-road-map-table-info {
    width: 540px;
    background-color: #000000;
    position: relative;
    .MuiAppBar-root {
        height: 28px;
        position: static;
        .sedie-table-info-tabs {
            min-height: 28px;
            height: 28px;
            background-color: #000000;
            font-size: 15px;
            .MuiTabs-indicator {
                display: none;
            }
            .sedie-table-info-road-tab {
                width: 25%;
                min-height: 28px;
                height: 28px;
                text-transform: none;
            }
            .Mui-selected {
                background-color: #8b7554;
                color: #ffffff;
            }
        }
    }
}