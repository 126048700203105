.dragon-tiger.table-info {
    .poker-box {
        div.poker:nth-child(1) {
            margin-right: 79px;
        }
        div.poker:nth-child(2) {
            margin-left: 79px;
        }
    }
    .point-box {
        position: absolute !important;
        bottom: 25px;
        width: 460px;
        .MuiTypography-root {
            width: 220px;
            text-transform: uppercase;
            font-size: 36px;
        }
    }
}
