.bet-options,
.bet-options * {
    box-sizing: border-box;
}
.bet-options {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}
.bet-options__seat {
    color: #baa378;
    text-align: center;
    font-family: 'Arial-Regular', sans-serif;
    font-size: 16px;
    font-weight: 400;
    position: relative;
}
.bet-options__bet-area-container {
    flex-shrink: 0;
    width: 120px;
    height: 112px;
    position: static;
}
.bet-options__bet-area {
    width: 120px;
    height: 112px;
    position: absolute;
    right: 6.52%;
    left: 6.52%;
    width: 86.96%;
    bottom: 33.66%;
    top: 10.89%;
    height: 55.45%;
    overflow: visible;
}
.bet-options__pair-bet {
    width: 32px;
    height: 32px;
    position: static;
}
.bet-options__chip-black-white-png-1 {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 53px;
    top: 22px;
    object-fit: cover;
}
.bet-options___1 {
    color: #ffffff;
    text-align: left;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    position: absolute;
    left: 65px;
    top: 22px;
    width: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.bet-options__main-bet {
    width: 64px;
    height: 63.73px;
    position: static;
}
.bet-options__chip-black-white-png-12 {
    width: 64px;
    height: 63.73px;
    position: absolute;
    left: 38px;
    top: 60.83px;
    object-fit: cover;
}
.bet-options___12 {
    color: #ffffff;
    text-align: left;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 24px;
    line-height: 16px;
    font-weight: 500;
    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 22.5px);
    width: 12px;
    height: 28.88px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.bet-options__button-panel {
    width: 138px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
}
.bet-options__button-panel-empty {
    width: 128px;
    height: 64px;
}
.bet-options__button {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    position: static;
}
.bet-options__clear {
    background: rgba(50, 42, 29, 0.8);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    left: 0px;
    top: 0px;
}
.bet-options__icon-close {
    border-radius: 8px;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
}
.bet-options__group {
    height: auto;
    position: absolute;
    right: 0%;
    left: 0%;
    width: 100%;
    bottom: 0%;
    top: 0%;
    height: 100%;
    overflow: visible;
}
.bet-options__ellipse-10 {
    border-radius: 50%;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
    border-width: 1px;
    width: 56px;
    height: 56px;
    position: absolute;
    left: 4px;
    top: 4px;
}
.bet-options__confirm {
    background: rgba(111, 95, 69, 0.8);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    left: 74px;
    top: 0px;
}
.bet-options__icon-check {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    position: relative;
    overflow: hidden;
}
.bet-options__group2 {
    height: auto;
    position: absolute;
    right: 10.42%;
    left: 6.25%;
    width: 83.33%;
    bottom: 20.14%;
    top: 20.83%;
    height: 59.03%;
    overflow: visible;
}
.bet-options__ellipse-102 {
    border-radius: 50%;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
    border-width: 1px;
    width: 56px;
    height: 56px;
    position: absolute;
    left: 78px;
    top: 4px;
}

.bet-options__bet-area__pair-label {
    fill: #ffffff;
    text-align: center;
    font-family: 'GothicA1-Medium', sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}
