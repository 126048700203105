.andar-bahar-table-info {
    width: 540px;
    background-color: #000000;
    position: relative;

    .MuiAppBar-root {
        height: 28px;
        position: static;

        .roadmap-type-button {
            min-width: 33px;
            width: 33px;
            min-height: 28px;
            height: 28px;
        }

        .andar-bahar-table-info-tabs {
            min-height: 28px;
            height: 28px;
            background-color: #000000;
            font-size: 15px;

            .MuiTabs-indicator {
                display: none;
            }

            .andar-bahar-table-info-tab {
                width: 166px;
                min-height: 28px;
                height: 28px;
                text-transform: none;
            }

            .andar-bahar-table-info-win-stat-tab {
                width: 175px;
                min-height: 28px;
                height: 28px;
                text-transform: none;
            }

            .Mui-selected {
                background-color: #8b7554;
                color: #ffffff;
            }
        }
    }
}