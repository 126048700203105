.FeedbackForm {
    // margin-top: 122px;
    //     margin-left: 0px;
        overflow-y: scroll;
        // max-height: 838px;
    // .MuiPaper-root {
    //     align-content: center
    // }
    .FeedbackRating {
        // width: 486px;
        height: 110px;
        font-size: 18px;
        color: #c3a475;
        margin-left: 27px;
        margin-right: 27px;
        justify-content: flex-end;
    }

    .OpinionsTextField {
        margin-left: 27px;
        margin-right: 27px;

        label {
            margin-top: 27px;
            margin-bottom: 10px;
        }
        #feedback-text-opinions {
            background-color: #707070;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            padding: 5px;
        }
    }

    .MuiFormLabel-root {
        font-size: 16px;
        color: #c3a475;
    }

    .MuiDivider-root {
        border-top: 2px;
        border-color: rgba($color: #ffffff, $alpha: 0.3);
        margin-left: 27px;
        margin-right: 27px;
    }

    .SubmitButton {
        margin-left: 27px;
        margin-right: 27px;
        align-content: center;
        align-items: center;
        scale: 0.8;

        // .MuiButtonBase-root {
        //     width: 83px;
        //     height: 83px;
        // }
    }
}
