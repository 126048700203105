.two-column-main-container {
    .blackjack-road {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 265px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
        .blackjack-point-cell {
            min-height: 1.5rem;
        }
        .blackjack-card-cell-text {
            font-size: 1rem !important;
        }
        .blackjack-card-cell-suit {
            zoom: 0.65 !important;
        }
    }
}

.one-column-main-container {
    .blackjack-road {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 540px;
        height: 224px;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 1fr;
        .blackjack-point-cell {
            min-height: 1.5rem;
        }
        .blackjack-card-cell-text {
            font-size: 1.35rem;
        }
        .blackjack-card-cell-suit {
            zoom: 0.85 !important;
        }
    }
}

.switch-table-container {
    .blackjack-road {
        background-color: white;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 182px;
        height: 94px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
        line-height: 0px;
        .blackjack-point-cell {
            min-height: 1.05rem;
            p {
                font-size: 0.7rem;
            }
        }
        .blackjack-card-cell-text {
            font-size: 0.7rem;
        }
        .blackjack-card-cell-suit {
            zoom: 0.5 !important;
        }
    }
}

.blackjack-road {
    .blackjack-column:nth-of-type(even) {
        background-color: #e1e1e1;
        .blackjack-point-cell {
            background-color: #252525;
        }
    }
    .blackjack-column:nth-of-type(odd) {
        background-color: white;
        .blackjack-point-cell {
            background-color: #464646;
        }
    }
    .blackjack-point-cell.blackjack-result-bust {
        background-color: #c70000 !important;
    }

    .blackjack-column.blackjack-column-last {
        background-color: #f3d1b5 !important;
    }
}
