.blackjack-mask {
    position: absolute;
    z-index: -1;
}
.blackjack-mask.betting {
    top: -64px;
    left: -30px;
}
.blackjack-mask.gaming {
    top: -35px;
    left: -39px;
}
