.progress.loading {
    position: relative;
    width: 100%;
    height: calc(100% - 8rem);
    min-height: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        width: 42%;
        max-width: 200px;
        min-width: 120px;
        height: auto;
    }

    .loading-icon {
        position: absolute;
        width: 68%;
        max-width: 360px;
        min-width: 170px;
        height: auto;
        -webkit-animation: spin 6s linear infinite;
        -moz-animation: spin 6s linear infinite;
        animation: spin 6s linear infinite;
    }
}
.progress.loading.overlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
