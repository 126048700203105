.bj-table-info {
    width: 540px;
    height: 127px;
    background-color: #000000;
    position: relative;
    bottom: 0;
    .blackjack-table-info {
        background: rgba(50, 42, 29, 0.6);
        padding: 12px 8px 4px 8px;
        height: 100%;
        .blackjack-table-info-item {
            font-size: 1.1rem;
            .item-title {
                color: #706046;
            }
            .item-value {
                color: #ffffff;
            }
        }
    }
}
