.sic-bo {
    .history-road {
        display: flex;
        flex-direction: row;
        .column:nth-of-type(even) {
            .column-top {
                background-color: #e1e1e1;
            }
            .column-bottom {
                background-color: #808080;
            }
        }
        .column:nth-of-type(odd) {
            .column-bottom {
                background-color: #999999;
            }
        }
        .column {
            .column-bottom {
                .small {
                    height: 25px;
                    background-color: #24268e;
                }
                .big {
                    height: 25px;
                    background-color: #9f0000;
                }
                .dice_triple {
                    background-color: #009304;
                }
            }
        }
        .column.last {
            .column-top {
                background-color: #f3d1b5 !important;
            }
        }
    }
}
.sic-bo.one-column-main-container {
    .column {
        width: 54px;
        .column-top {
            justify-content: space-between;
            padding: 13px 0 5.5px 0;
            height: 143.5px;
            font-size: 17px;
            color: black;
        }
        .column-bottom {
            height: 50px;
            .odd {
                height: 25px;
                background-color: #24268e;
            }
            .even {
                height: 25px;
                background-color: #c70000;
            }
            .dice_triple {
                height: 50px;
            }
        }
    }
}
.sic-bo.two-column-main-container {
    .column {
        width: 37.857px;
        .column-top {
            height: 80px;
            div.dices_stroke {
                margin-top: 13.75px;
            }
        }
        .column-bottom {
            height: 17px;
            font-size: 12px;
            .MuiGrid-item {
                height: 17px !important;
            }
        }
    }
}

.sicbo-switch-table-map-main-container {
    .big-small-road {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 180px;
        height: 94px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.sicbo-roadmap-container {
    .roadmap-tabs {
        height: 29px;
        min-height: 29px;
        .MuiButtonBase-root.MuiTab-root {
            width: 180px;
            height: 29px;
            min-width: 180px;
            min-height: 29px;
            text-transform: none;
            font-size: 14px;
            background-color: #6a573d;
            padding: 0;
            margin: 0;
            color: rgba(255, 255, 255, 0.6);
        }
        .MuiTabs-indicator {
            display: none;
        }
        .Mui-selected {
            background-color: #6a573d;
            color: #ffffff !important;
        }
    }
    .roadmap-tab {
        position: relative;
        width: 100%;
        height: 133px;
        .point-road,
        .big-small-road,
        .odd-even-road {
            position: absolute;
            top: 0.5px;
            left: 0.5px;
            width: 528px;
            height: 133px;
            display: grid;
            grid-template-columns: repeat(24, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-auto-flow: column;
        }
    }
    .summary-list {
        width: 100%;
        height: 28px;
        p {
            margin-left: 10px;
            font-size: 16px;
        }
        .summary.road\.big {
            color: #ff0000;
        }
        .summary.road\.small {
            color: #3962E7;
        }
        .summary.road\.odd {
            color: #3962E7;
        }
        .summary.road\.even {
            color: #ff0000;
        }
        .summary.road\.dice_triple {
            color: #009303;
        }
    }
}
