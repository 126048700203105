.pokdeng-oneColumnMainContainer {
    width: 540px;
    height: 193px;
    background-color: #ffffff;
    position: relative;
    .pokdeng-label {
        position: absolute;
        width: 60px;
        height: 192px;
    }
    .pokdeng-grid {
        position: absolute;
        left: 60px;
        width: 480px;
        height: 192px;
    }
    .pokdeng-record {
        position: absolute;
        top: 1px;
        left: 60px;
        width: 475px;
        height: 192px;
        display: grid;
        grid-template-columns: repeat(15, 32px);
        grid-template-rows: 1fr;
        line-height: 0.6;
    }
}

.pokdeng-twoColumnMainContainer {
    width: 265px;
    height: 97px;
    background-color: #ffffff;
    position: relative;
    .pokdeng-label {
        position: absolute;
        width: 40px;
        height: 97px;
    }
    .pokdeng-grid {
        position: absolute;
        left: 40px;
        width: 225px;
        height: 97px;
    }
    .pokdeng-record {
        position: absolute;
        top: 0px;
        left: 40px;
        width: 225px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(15, 16px);
        grid-template-rows: 1fr;
        line-height: 0;
    }
}

.pokdeng-ingame-main-container {
    width: 540px;
    height: 138px;
    background-color: #ffffff;
    position: relative;
    .pokdeng-label {
        position: absolute;
        width: 57px;
        height: 138px;
    }
    .pokdeng-grid {
        position: absolute;
        left: 57px;
        width: 483px;
        height: 138px;
    }
    .pokdeng-record {
        position: absolute;
        top: 1px;
        left: 57px;
        width: 483px;
        height: 138px;
        display: grid;
        grid-template-columns: repeat(21, 23px);
        grid-template-rows: 1fr;
        line-height: 0.6;
    }
    .pokdeng-statistic {
        position: absolute;
        top: 138px;
        width: 540px;
        height: 24px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
    }
}
.pokdeng-ingame-main-container {
    width: 540px;
    height: 138px;
    background-color: #ffffff;
    position: relative;
    .pokdeng-label {
        position: absolute;
        width: 57px;
        height: 138px;
    }
    .pokdeng-grid {
        position: absolute;
        left: 57px;
        width: 483px;
        height: 138px;
    }
    .pokdeng-record {
        position: absolute;
        top: 0px;
        left: 57px;
        width: 483px;
        height: 138px;
        display: grid;
        grid-template-columns: repeat(21, 23px);
        grid-template-rows: 1fr;
        line-height: 0.5;
    }
}
.pokdeng-switch-table-container {
    width: 182px;
    height: 94px;
    background-color: #ffffff;
    position: relative;
    .pokdeng-label {
        position: absolute;
        width: 26px;
        height: 94px;
    }
    .pokdeng-grid {
        position: absolute;
        left: 26px;
        width: 156px;
        height: 94px;
    }
    .pokdeng-record {
        position: absolute;
        top: 0px;
        left: 26.5px;
        width: 148px;
        height: 94px;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 1fr;
        line-height: 0;
    }
}
