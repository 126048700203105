.MuiSlider-track {
    border: 1px solid #1a625f !important;
    background-color: #1a625f !important;
}
.MuiSlider-rail {
    background-color: #3c3c3c !important;
    opacity: 1 !important;
}
.MuiSlider-thumb {
    width: 35px !important;
    height: 35px !important;
    background-color: #1a625f !important;
}