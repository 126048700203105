.betArea-container {
    .bet-statistics {
        position: absolute;
        font-size: 14px;
        .s1 {
            position: absolute;
            bottom: 10px;
            left: 6px;
            .MuiBox-root {
                position: relative;
                display: inline-flex;

                .MuiBox-root {
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 45px;
                    .MuiTypography-root {
                        color: #ffffff;
                    }
                }
            }
        }
        .s2 {
            position: absolute;
            bottom: 19px;
            right: 5px;
            div {
                margin-right: 5px;
            }
        }
        .s3 {
            position: absolute;
            bottom: 3px;
            right: 5px;
            div {
                margin-right: 5px;
            }
        }
    }
}
