.two-column-main-container {
    direction: ltr;
    .bigRoad {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 256px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.one-column-main-container {
    .beadRoad {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 192px;
        height: 193px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigRoad {
        position: absolute;
        top: 0px;
        left: 193px;
        width: 336px;
        height: 97px;
        display: grid;
        grid-template-columns: repeat(21, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigEyeRoad {
        position: absolute;
        top: 97px;
        left: 193px;
        width: 334px;
        height: 48px;
        display: grid;
        grid-template-columns: repeat(42, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .smallRoad {
        position: absolute;
        top: 145px;
        left: 193px;
        width: 168px;
        height: 48px;
        display: grid;
        grid-template-columns: repeat(21, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .cockroachRoad {
        position: absolute;
        top: 145px;
        left: 369px;
        width: 168px;
        height: 48px;
        display: grid;
        grid-template-columns: repeat(21, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}

.BaccaratInGameMainContainer {
    direction: ltr;
    width: 540px;
    height: 199px;
    background-color: #ffffff;
    position: relative;

    .beadRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 224px;
        height: 168px;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigRoad {
        position: absolute;
        top: 0px;
        left: 225px;
        width: 308px;
        height: 84px;
        display: grid;
        grid-template-columns: repeat(22, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .bigEyeRoad {
        position: absolute;
        top: 85px;
        left: 225px;
        width: 308px;
        height: 42px;
        display: grid;
        grid-template-columns: repeat(44, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .smallRoad {
        position: absolute;
        top: 127px;
        left: 225px;
        width: 154px;
        height: 42px;
        display: grid;
        grid-template-columns: repeat(22, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .cockroachRoad {
        position: absolute;
        top: 127px;
        left: 378.5px;
        width: 154px;
        height: 42px;
        display: grid;
        grid-template-columns: repeat(22, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }

    .AskRoad {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 28px;
        background-color: #202020;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;

        .summaryList {
            display: flex;
            direction: row;
            gap: 7px;
            padding-left: 10px;
            align-self: center;

            .banker {
                color: #c70000;
            }

            .player {
                color: #2d30b1;
            }

            .tie {
                color: #009303;
            }
        }

        .askBtn {
            display: flex;
            direction: row;
            gap: 10px;
            padding-right: 10px;
        }
    }
}

.BaccaratMultiBetMainContainer {
    width: 540px;
    height: 127px;
    background-color: #ffffff;
    position: relative;

    .roadContainer {
        top: 0px;
        left: 0px;
        width: 1234px;
        height: 286px;
        position: absolute;
        scale: 0.437;
        transform-origin: top left;
        .bigRoad {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 1210px;
            height: 143px;
            display: grid;
            grid-template-columns: repeat(51, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-auto-flow: column;
        }
        .bigEyeRoad {
            position: absolute;
            top: 144px;
            left: 0px;
            width: 1080px;
            height: 66px;
            display: grid;
            grid-template-columns: repeat(104, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-auto-flow: column;
        }

        .smallRoad {
            position: absolute;
            top: 214px;
            left: 0px;
            width: 616px;
            height: 70px;
            display: grid;
            grid-template-columns: repeat(56, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-auto-flow: column;
        }
        .cockroachRoad {
            position: absolute;
            top: 214px;
            left: 617px;
            width: 464px;
            height: 70px;
            display: grid;
            grid-template-columns: repeat(42, 1fr);
            grid-template-rows: repeat(6, 1fr);
            grid-auto-flow: column;
        }
    }
}

.askBtnGrp {
    gap: 2px;

    .askBanker {
        width: 64px;
        height: 20px;
        background-color: #c70000;
        border-radius: 5px;
    }

    .askPlayer {
        width: 64px;
        height: 20px;
        background-color: #2d30b1;
        border-radius: 5px;
    }

    svg.roadmap-cell {
        width: 20px;
        height: 20px;
    }
}

.SwitchTableMapMainContainer {
    background-color: #ffffff;
    .switch-grid {
        top: 0.5px;
        left: 0.5px;
        width: 180px;
        height: 94px;
    }
    .bigRoad {
        position: absolute;
        top: 0.5px;
        left: 0.5px;
        width: 185px;
        height: 94px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-auto-flow: column;
    }
}
