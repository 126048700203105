.dragon-tiger.table-info {
    width: 540px;
    background-color: #000000;
    position: relative;
    .MuiAppBar-root {
        height: 30px;
        position: static;
        .table-info-tabs {
            min-height: 30px;
            height: 30px;
            background-color: #000000;
            font-size: 15px;
            .MuiTabs-indicator {
                display: none;
            }
            .MuiButtonBase-root.MuiTab-root {
                width: 180px;
                min-height: 30px;
                height: 30px;
                text-transform: none;
                padding-left: 0;
                padding-right: 0;
            }
            .Mui-selected {
                background-color: #8b7554;
                color: #ffffff;
            }
        }
    }
}
